import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import { ClassNameMap } from "@material-ui/styles";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tooltip: {
    maxWidth: 250,
    padding: theme.spacing(0.5, 1),
  },
  tooltipDisabled: {
    display: "none",
  },
}));

export type TooltipJSSClassKey = keyof ReturnType<typeof useStyles>;

export type TooltipProps = Omit<MuiTooltipProps, "classes"> & {
  className?: string;
  classes?: Partial<ClassNameMap<TooltipJSSClassKey>>;
  TooltipClasses?: MuiTooltipProps["classes"];
  disabled?: boolean;
  disableTooltip?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({
  className,
  classes: extClasses,
  children,
  disabled,
  TooltipClasses,
  ...rest
}) => {
  const classes = useStyles({ classes: extClasses });

  return (
    <MuiTooltip
      className={clsx(className, classes.root)}
      classes={{
        ...TooltipClasses,
        tooltip: clsx(classes.tooltip, TooltipClasses?.tooltip, {
          [classes.tooltipDisabled]: disabled,
        }),
      }}
      arrow
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
